import { IActionContext } from '@msdyn365-commerce/core';
import { IStoreSelectorLocation } from '@msdyn365-commerce-modules/bopis-utilities/src/store-selector-state-manager/i-store-selection-state-manager';
import {
    IMapWindow,
    MapLocationsResult
} from '@msdyn365-commerce-modules/bopis-utilities/src/store-selector-state-manager/models/map-data';
declare const window: IMapWindow;
/**
 * Instance of IStoreSelectorStateManager manager that uses Map service
 * for geo location.
 */
// export class MapStoreSelectorStateManager extends BaseStoreSelectorStateManager {
//     private readonly SERVICE_ENDPOINT: string = 'https://dev.virtualearth.net/REST/v1/';
//     private readonly MAP_API_ENDPOINT: string = 'https://www.bing.com/api/maps/mapcontrol?callback=mapAPIReady';
//     @action
//     public async geoLocate(searchTerm: string, actionContext: IActionContext): Promise<IStoreSelectorLocation | undefined> {
//         if (
//             !searchTerm ||
//             !actionContext.requestContext.channel ||
//             !actionContext.requestContext.channel.BingMapsApiKey ||
//             !actionContext.requestContext.channel.BingMapsEnabled
//         ) {
//             return undefined;
//         }
//         let searchContent: string = searchTerm;
//         const jsonp = `mapSearchCallback_${crypto.getRandomValues(new Uint32Array(1))[0]}`;
//         if (actionContext.requestContext.channel.ChannelCountryRegionISOCode) {
//             searchContent = `${searchTerm.trim()} ${actionContext.requestContext.channel.ChannelCountryRegionISOCode}`;
//         }
//         const query = encodeURI(searchContent);
//         const key = encodeURI(actionContext.requestContext.channel.BingMapsApiKey);
//         const geolocationApiUrl =
//             actionContext.requestContext.app &&
//             actionContext.requestContext.app.config &&
//             actionContext.requestContext.app.config.geolocationApiUrl;
//         const requestUrl = `${
//             geolocationApiUrl ? geolocationApiUrl : this.SERVICE_ENDPOINT
//         }/Locations?query=${query}&key=${key}&jsonp=${jsonp}`;
//         return new Promise<IStoreSelectorLocation | undefined>(resolve => {
//             const script = document.createElement('script');
//             script.setAttribute('type', 'text/javascript');
//             script.setAttribute('src', requestUrl);
//             window[jsonp] = (data: MapLocationsResult) => {
//                 delete window[jsonp];
//                 script.remove();
//                 if (data && data.resourceSets && data.resourceSets.length > 0 && data.resourceSets[0].resources.length > 0) {
//                     resolve({
//                         latitude: data.resourceSets[0].resources[0].point.coordinates[0],
//                         longitude: data.resourceSets[0].resources[0].point.coordinates[1]
//                     });
//                 }
//                 resolve(undefined);
//             };
//             document.body.appendChild(script);
//         });
//     }
//     @action
//     public async loadMapApi(input: ILoadMapApiInput): Promise<void> {
//         alert('eeeep');
//         if (StringExtensions.isNullOrEmpty(input.key)) {
//             return Promise.resolve();
//         }
//         const isScriptAlreadyLoaded = !!document?.getElementById('msdyn_map-api-script');
//         if (isScriptAlreadyLoaded) {
//             // Prevents from loading multiple script into the body
//             return Promise.resolve();
//         }
//         const url = `${this.MAP_API_ENDPOINT}${input.key ? `&key=${input.key}` : ''}${input.lang ? `&setLang=${input.lang}` : ''}${
//             input.market ? `&setMkt=${input.market}` : ''
//         }`;
//         return new Promise((resolve, reject) => {
//             const script = document?.createElement('script');
//             script.type = 'text/javascript';
//             script.async = true;
//             script.defer = true;
//             script.src = url;
//             script.id = 'msdyn_map-api-script';
//             if (window) {
//                 window.mapAPIReady = () => {
//                     this.isMapApiLoaded = true;
//                     resolve();
//                 };
//             }
//             script.addEventListener('error', (error: string | Event) => {
//                 reject(error);
//             });
//             document?.body?.appendChild(script);
//         });
//     }
// }
// export function createStoreSelectorStateInput(result: IStoreSelectorStateManager): GenericInput<IStoreSelectorStateManager> {
//     return new GenericInput<IStoreSelectorStateManager>('mapSSSM', result, 'IStoreSelectorStateManager');
// }
// const createStoreSelectorStateInputInternal = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
//     return createStoreSelectorStateInput(new MapStoreSelectorStateManager());
// };
// export const getGenericActionDataAction = createObservableDataAction({
//     action: <IAction<IStoreSelectorStateManager>>getGenericAction,
//     input: createStoreSelectorStateInputInternal
// });
// export default getGenericActionDataAction;
const SERVICE_ENDPOINT: string = 'https://dev.virtualearth.net/REST/v1/';
export const geoLocate = async (searchTerm: string, actionContext: IActionContext): Promise<IStoreSelectorLocation | undefined> => {
    if (
        !searchTerm ||
        !actionContext.requestContext.channel ||
        !actionContext.requestContext.channel.BingMapsApiKey ||
        !actionContext.requestContext.channel.BingMapsEnabled
    ) {
        return undefined;
    }
    let searchContent: string = searchTerm;
    const jsonp = `mapSearchCallback_${crypto.getRandomValues(new Uint32Array(1))[0]}`;
    if (actionContext.requestContext.channel.ChannelCountryRegionISOCode) {
        // searchContent = `${searchTerm.trim()} ${actionContext.requestContext.channel.ChannelCountryRegionISOCode}`;
    }
    const query = encodeURI(searchContent);
    const key = encodeURI(actionContext.requestContext.channel.BingMapsApiKey);
    const geolocationApiUrl =
        actionContext.requestContext.app &&
        actionContext.requestContext.app.config &&
        actionContext.requestContext.app.config.geolocationApiUrl;
    const requestUrl = `${geolocationApiUrl ? geolocationApiUrl : SERVICE_ENDPOINT}/Locations?query=${query}&key=${key}&jsonp=${jsonp}`;
    return new Promise<IStoreSelectorLocation | undefined>(resolve => {
        const script = document.createElement('script');
        script.setAttribute('type', 'text/javascript');
        script.setAttribute('src', requestUrl);
        window[jsonp] = (data: MapLocationsResult) => {
            delete window[jsonp];
            script.remove();
            if (data && data.resourceSets && data.resourceSets.length > 0 && data.resourceSets[0].resources.length > 0) {
                resolve({
                    latitude: data.resourceSets[0].resources[0].point.coordinates[0],
                    longitude: data.resourceSets[0].resources[0].point.coordinates[1]
                });
            }
            resolve(undefined);
        };
        document.body.appendChild(script);
    });
};
